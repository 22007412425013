import React, { useState } from 'react';

interface YearSelectorProps {
    onChange: (selectedYear: number) => void;
}

const YearSelector: React.FC<YearSelectorProps> = ({ onChange }) => {

    const calculateYears =(): number[] => {
        const startYear: number = 2024;
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // Months are zero-based: 0 = January, 10 = November

        const endYear = currentMonth >= 10 ? currentYear + 1 : currentYear; // Add 1 if November or later
        const years = [];

        for (let year: number = startYear; year <= endYear; year++) {
            // @ts-ignore
            years.push(year);
        }

        return years;
    }

    const currentYear = new Date().getFullYear();
    const years = calculateYears();

    const [selectedYear, setSelectedYear] = useState<number>(currentYear);

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue: number = parseInt(event.target.value);
        setSelectedYear(selectedValue);
        onChange(selectedValue);
    };

    return (
        <div>
            <select id="yearSelect" value={selectedYear} onChange={handleYearChange} className={"form-control"}>
                {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
        </div>
    );
};

export default YearSelector;
