import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Button, Col, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {LEGAL_WARNING_DOC_URL, ROUTE_PATHS} from "Config/Router/Routes";
import {Notificator} from "Services/Notificator/Notificator";
import {Cart as CartModel, CartItem, getCart} from "Api/Query/CartQuery";
import {useMutation, useQuery} from "react-query";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {appLoading} from "Store/loadingSlice";
import {useDispatch} from "react-redux";
import {formatMoneyValue, MoneyAmount} from "Components/Money/MoneyAmount";
import {
    DISCOUNT_TYPE_ORDINARY_ID,
    DISCOUNT_TYPE_PERCENTS_BY_ID,
    DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID,
    DiscountTypeSelect
} from "Components/InscriptionOrder/DiscountTypeSelect";
import {createFullInscriptionOrder} from "Api/Mutation/InscriptionOrder";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {setTotalCartCounter} from "Store/cartSlice";

export const InvoiceDetailRequest: React.FC = () => {

    const [cart, setCart] = useState<CartModel>()
    const [cartItems, setCartItems] = useState<CartItem[]>([])
    const [discountType, setDiscountType] = useState<any>(null)
    const [acceptedInscriptionTerms, setAcceptedInscriptionTerms] = useState<boolean>(false)
    const [socialWorker, setSocialWorker] = useState<string>('')
    const [discountAmount, setDiscountAmount] = useState<number>(0)
    const [extraDiscount, setExtraDiscount] = useState<any>(null)
    const [extraDiscountChecked, setExtraDiscountChecked] = useState<boolean>(false)
    const [extraDiscountValue, setExtraDiscountValue] = useState<string>('')

    const [activityPrices, setActivityPrices] = useState<any>({})
    const [subtotalPrice, setSubtotalPrice] = useState<number>(0)
    const [finalPrice, setFinalPrice] = useState<number>(0)
    const [totalDiscountAmount, setTotalDiscountAmount] = useState<number>(0)

    const initFormErrors = {
        discountType: '',
        terms: '',
        socialWorker: '',
    }
    const [formErrors, setFormErrors] = useState(initFormErrors)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isFormValid = (): boolean => {
        let errors = 0
        let errorsList: any = initFormErrors

        if (!discountType) {
            errorsList = {...errorsList, discountType: 'El tipo de descuento es obligatorio.'}
            errors++
        }

        if (discountType &&
            discountType.value === DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID
            && !socialWorker
        ) {
            errorsList = {
                ...errorsList,
                socialWorker: 'El trabajador social es obligatorio para este tipo de descuento.'
            }
            errors++
        }

        if (!acceptedInscriptionTerms) {
            errorsList = {...errorsList, terms: 'Tienes que aceptar las `Condiciones de Inscripción`'}
            errors++
        }

        setFormErrors(errorsList)

        if (errors > 0) {
            return false
        }

        return true
    }

    const calculatePrices = () => {
        if(!cart){
            return
        }

        let totalDiscounts = 0
        let currentFinalPrice = 0
        let currentSubtotalPrice = 0
        const priceByCartItem : any = {}

        cartItems.map((item: CartItem) => {
            // count service prices
            if (item.services.length) {
                item.services.map(service => {
                    currentFinalPrice += service.price
                    currentSubtotalPrice += service.price
                })
            }

            // has extra discount
            if(item.discount && extraDiscountValue && item.discount.id === extraDiscount.id && extraDiscountValue){
                const discountAmount = item.price * (extraDiscount.value/100)

                // save in priceByActivities object
                const newCartItemPrice = item.price - discountAmount
                priceByCartItem[item.id] = newCartItemPrice < 0 ? 0 : newCartItemPrice
                totalDiscounts += discountAmount

                currentFinalPrice += newCartItemPrice
            }else{
                currentFinalPrice += item.price
            }

            currentSubtotalPrice += item.price
        })

        // apply global discount
        if(discountType && discountType?.value !== DISCOUNT_TYPE_ORDINARY_ID){
            const discountAmount = calculateDiscountAmount(cart.totalPrice)
            currentFinalPrice -= discountAmount
            totalDiscounts += discountAmount
        }

        // calculate price
        setFinalPrice(currentFinalPrice)
        setSubtotalPrice(currentSubtotalPrice)
        setActivityPrices(priceByCartItem)
        setTotalDiscountAmount(totalDiscounts)
    }

    const cartDetailQuery = useQuery({
        queryKey: ['cartDetail'],
        queryFn: () => getCart(),
        onSuccess: (data: any) => {
            const response = data.data._result
            setCart(response)
            setCartItems(response.items)

            // extra discount
            response.items.map((i:CartItem) => {
                if(i.discount && !extraDiscount){
                    setExtraDiscount(i.discount)
                }
            })
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const createInscriptionFullOrderMutation = useMutation({
        mutationFn: (
            body: {
                discountType: string,
                socialWorker: string,
                extraDiscountValue: string
            }
        ) => createFullInscriptionOrder(
            body.discountType,
            body.socialWorker,
            body.extraDiscountValue
        ),
        onSuccess: (response: any) => {
            dispatch(setTotalCartCounter(0))
            Notificator.success('Su solicitud ha sido registrada satisfactoriamente')
            navigate(ROUTE_PATHS.REQUEST_ITEMS_LIST)
        },
        onError: (error: any) => {
            let errorMessage = 'Se ha producido un error al crear la inscripción.'

            if (error.response.data._error.message) {
                errorMessage = error.response.data._error.message
            }

            Notificator.error(errorMessage, 'Error')
            dispatch(appLoading({value: false}))
        }
    })

    const submit = (): void => {
        if (!isFormValid()) {
            Notificator.error('Los campos en rojo son obligatorios.')
            return
        }

        dispatch(
            appLoading({
                value: true,
                text: 'Enviando la solicitud ...'
            })
        )

        createInscriptionFullOrderMutation.mutate({
            discountType: discountType ? discountType.value : '',
            socialWorker: socialWorker,
            extraDiscountValue: extraDiscountValue
        })
    }

    function createActivityContent(item: CartItem) {
        let description = `
            <strong>Nombre del menor:</strong> ${item.child.name}<br /> 
            <strong>Centro:</strong> ${item.center.name}<br /> 
            <strong>Actividad:</strong> ${item.name}<br />`
        let priceCalculation = item.price

        if (item.activitySession) {
            description += `<strong>Modalidad:</strong> ${item.activitySession.name}`
        }

        // days
        description += `<br /><strong>Días:</strong><br /><ul>`
        item.days.map(day => {
            const dayValue = ('name' in day) ? day.name : day
            let priceLabel = ': ' + formatMoneyValue(day.price)
            let price = (item.initDate || !item.activitySession) ? '' : priceLabel
            price = (item.activity?.canSelectMultipleYearDays===true) ? priceLabel : price

            description += `<li><strong>${dayValue}</strong>${price}</li>`
        })
        description += '</ul>'

        if (item.services.length) {
            description += `<strong>Servicios:</strong><br /><ul>`

            item.services.map(service => {
                description += `<li><strong>${service.name}</strong>: ${formatMoneyValue(service.price)}</li>`
                priceCalculation += service.price
            })

            description += `</ul>`
        }

        if(item.initDate){
            const pendingMessage = 'Pendiente hasta la validación por el Servicio de Educación, que avisará a la familia de la fecha de incorporación'
            const initDate = (item.activitySession.id === '01925120-15e6-7237-acad-2070bfef156e') ? pendingMessage : item.initDate
            description += `<strong>Fecha de Inicio:</strong> ${initDate}`
        }

        if(priceCalculation){
            description += `<br /><strong>Precio:</strong> ${formatMoneyValue(priceCalculation)}</span>`
        }

        if (item.observations) {
            description += `<br /><strong>Observaciones:</strong><p>${item.observations}</p>`
        }

        if(item.discount && extraDiscountValue && item.discount.id === extraDiscount.id){
            const discountAmount = priceCalculation * (extraDiscount.value/100)
            description += `<br /><strong>Descuento:</strong> ${extraDiscount.name} &nbsp; <span class="text-danger">-${formatMoneyValue(discountAmount)}</span>`
        }

        return {__html: description};
    }

    const onDiscountTypeChange = (discountTypeOption: any, socialWorker: string) => {
        setDiscountType(discountTypeOption)
        setSocialWorker(socialWorker)

        if(!cart){
            return
        }

        // Apply discount
        const discountValue = discountTypeOption?.value ?? 0;
        const discountPercent = DISCOUNT_TYPE_PERCENTS_BY_ID[discountValue]
        const discountAmountValue = cart.totalPrice * (discountPercent / 100)
        setDiscountAmount(discountAmountValue)

        const finalPrice = cart.totalPrice - discountAmountValue
        setFinalPrice(finalPrice)
    }

    const calculateDiscountAmount = (amount : number) : number => {
        const discountValue = discountType?.value ?? 0;
        const discountPercent = DISCOUNT_TYPE_PERCENTS_BY_ID[discountValue]
        return amount * (discountPercent / 100)
    }

    useEffect(() => {
        dispatch(
            appLoading({
                value: cartDetailQuery.isLoading,
                text: 'Cargando'
            })
        )
    }, [cartDetailQuery.isLoading])

    useEffect(() => {
        calculatePrices()
    }, [cartItems, extraDiscountValue, discountType]);

    return (
        <RequireAuth>
            <DefaultContainer>
                <Col id={"invoiceDetailRequest"}>
                    <h1 className={"pb-4"}>Detalles de facturación</h1>
                    <p>
                        <span className={"purple"}>Importante:</span> el servicio de Días Sueltos solo puede acogerse a
                        la solicitud de descuento por valoración de Servicios Sociales. El resto de descuentos no están
                        disponibles para esta modalidad.
                    </p>
                    <Row>
                        <h5>Indicar si se acoge a algún tipo de descuento (supervisión de servicios sociales, descuento
                            por desempleo o familia numerosa). En caso contrario, seleccionar "Ordinaria" en el caso de no acogerse a ningun descuento.
                            &nbsp;<span className={"text-danger"}>Campo obligatorio</span> <FormLabelRequiredAsterisk/>
                        </h5>
                        <Row>
                            <Col md={5}>
                                <DiscountTypeSelect
                                    onChange={onDiscountTypeChange}
                                    value={""}
                                    classes={formErrors.discountType ? "is-invalid" : ""}
                                    isSocialWorkerInvalid={!!formErrors.socialWorker}
                                />
                                {formErrors.discountType &&
                                    <p className={"text-danger pt-2"}>{formErrors.discountType}</p>}

                                {
                                    extraDiscount && <div className={"mt-3"}>
                                        <label
                                            htmlFor={"extraDiscount"}
                                            key={"extraDiscount"}
                                        >
                                            <input
                                                type="checkbox"
                                                id={"extraDiscount"}
                                                name={"extraDiscount"}
                                                checked={extraDiscountChecked || false}
                                                onChange={(e:any) => {
                                                    const checked = e.target.checked
                                                    setExtraDiscountChecked(checked)

                                                    if(checked) {
                                                        setExtraDiscountValue(extraDiscount.id)
                                                    }else {
                                                        setExtraDiscountValue('')
                                                    }
                                                }}
                                            />
                                            &nbsp; {extraDiscount.name}
                                        </label>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Row>

                    <h2 className={"purple yourRequestLabel"}>Tu solicitud</h2>

                    <table className={"redTable table table-bordered"}>
                        <thead>
                        <tr>
                            <th>SERVICIO</th>
                            <th>SUBTOTAL</th>
                        </tr>
                        </thead>
                        <tbody>

                        {cartItems?.map((item, key) => {
                            return (
                                <tr key={key}>
                                    <td className={"align-middle"}>
                                        <span className={"purple d-block"}>{item.name}</span>
                                        <div dangerouslySetInnerHTML={createActivityContent(item)}></div>
                                    </td>
                                    <td className={"align-middle"}>
                                        <MoneyAmount amount={item.totalPrice}/>
                                    </td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td>
                                <strong>SUBTOTAL</strong>
                            </td>
                            <td>
                                <MoneyAmount amount={subtotalPrice}/>
                            </td>
                        </tr>

                        {
                            totalDiscountAmount > 0 &&
                            <tr>
                                <td>
                                    <strong>DESCUENTOS</strong><br/>
                                    <ul className={"mt-3"}>
                                        {discountType && discountType.value !== DISCOUNT_TYPE_ORDINARY_ID && <li>{discountType.label}</li>}
                                        {extraDiscountValue && <li>{extraDiscount.name}</li>}
                                    </ul>
                                </td>
                                <td style={{"verticalAlign":"middle"}}>
                                    {
                                        cart && <>
                                            -<MoneyAmount amount={totalDiscountAmount}/>
                                        </>
                                    }
                                </td>
                            </tr>
                        }

                        <tr>
                            <td>
                                <strong>TOTAL</strong>
                            </td>
                            <td>
                                <MoneyAmount amount={finalPrice ?? 0}/>
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <p className={"my-24 personalDataInfo"}>Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web y otros propósitos descritos en nuestra <Link target={"_blank"} to={ROUTE_PATHS.PRIVACY_POLICY}>Política
                            de Privacidad</Link>.
                    </p>

                    <p className={"mt-3"}>
                        <label>
                            <input
                                type={"checkbox"}
                                onChange={(e: any) => setAcceptedInscriptionTerms(e.target.checked)}

                            />
                            &nbsp;&nbsp;<span className={formErrors.terms ? "text-danger" : ""}>Acepto las <a
                            href={LEGAL_WARNING_DOC_URL}
                            target={"_blank"}>Condiciones de Inscripción</a> <FormLabelRequiredAsterisk/></span>
                        </label>
                    </p>

                    <Button
                        className={"purple-btn"}
                        onClick={submit}
                    >Enviar la solicitud</Button>

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
