import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {useMutation, useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {
    exportAdminActivityReport,
    exportAdminInscriptionsReport,
    exportAdminPaymentReport
} from "Api/Query/InscriptionQuery";
import {RefreshSpinIcon} from "Components/Icons/RefreshSpinIcon";
import {FormLabelRequiredAsterisk} from "../../Components/Form/FormLabelRequiredAsterisk";
import MonthSelector from "../Datetime/MonthSelector";
import YearSelector from "../Datetime/YearSelector";
import {ActivitySelect} from "../../Components/Activities/ActivitySelect";
import {FileUpload} from "../../Components/FileUpload/FileUpload";
import {ErrorMessageBuilder} from "../../Services/Notificator/ErrorMessageBuilder";
import {uploadInscriptionReportFileForSepaGeneration} from "../../Api/Mutation/SepaXml";
import {forceDownloadFromUrl} from "../../Services/File/FileDownloader";

interface Props {
    showStatusFilter?: boolean,
    isSocialServiceUser?: boolean
}

export const AdminReportList: React.FC<Props> = (props) => {

    const ALLOWED_SEPA_XML_FILETYPES = [
        "application/vnd.ms-excel",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ]
    const [dropzoneFiles, setDropzoneFiles] = useState<any>([])
    const [collectionDate, setCollectionDate] = useState<string>('')

    const [exporting, setExporting] = useState<boolean>(false)
    const [exportingActivity, setExportingActivity] = useState<boolean>(false)
    const [exportingPayment, setExportingPayment] = useState<boolean>(false)

    const defaultSearchFilters = {
        year: new Date().getFullYear(), // current year
        month: -1
    }
    const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>(defaultSearchFilters)
    const [paymentSearchFilters, setPaymentSearchFilters] = useState<{ [key: string]: any }>(defaultSearchFilters)

    const defaultActivitySearchFilters = {
        year: new Date().getFullYear(), // current year
        month: -1
    }
    const [activitySearchFilters, setActivitySearchFilters] = useState<{
        [key: string]: any
    }>(defaultActivitySearchFilters)

    const dispatch = useAppDispatch()

    const downloadXML = (xmlContent: string, fileName: string) => {
        // Create a Blob from the XML string
        const blob = new Blob([xmlContent], {type: 'application/xml'});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.download = fileName;

        // Create an object URL for the Blob
        link.href = URL.createObjectURL(blob);

        // Append the link to the body (it's not visible)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up - remove the link element and revoke the object URL
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminReport'],
        queryFn: () => exportAdminInscriptionsReport(searchFilters),
        onSuccess: (response: any) => {
            if (response.data._result.message) {
                Notificator.warning(response.data._result.message)
            } else {
                forceDownloadFromUrl(response.data._result.url)
            }

            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: (e: any) => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const exportActivityQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminActivityReport'],
        queryFn: () => exportAdminActivityReport(activitySearchFilters),
        onSuccess: (response: any) => {
            if (response.data._result.message) {
                Notificator.warning(response.data._result.message)
            } else {
                forceDownloadFromUrl(response.data._result.url)
            }

            setExportingActivity(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: (e: any) => {
            Notificator.error('Se ha producido un error inesperado.')
            setExportingActivity(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const exportPaymentQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminPaymentReport'],
        queryFn: () => exportAdminPaymentReport(paymentSearchFilters),
        onSuccess: (response: any) => {
            if (response.data._result.message) {
                Notificator.warning(response.data._result.message)
            } else {
                forceDownloadFromUrl(response.data._result.url)
            }

            setExportingPayment(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExportingPayment(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    const excelActivityExport = () => {
        setExportingActivity(true)
        exportActivityQuery.refetch()
    }

    const excelPaymentExport = () => {
        setExportingPayment(true)
        exportPaymentQuery.refetch()
    }

    const uploadMutation = useMutation({
        mutationFn: (body: any) => uploadInscriptionReportFileForSepaGeneration(body.collectionDate, body.file),
        onMutate: (body: any) => {
            dispatch(
                appLoading({
                    value: true,
                    text: `Processando archivo: ${body.file.name} ...`
                })
            )
        },
        onError: (error: any) => {
            dispatch(appLoading({value: false}))

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        },
        onSuccess: (response: any) => {
            setDropzoneFiles([])

            // Get the current date and time
            const now = new Date();

            // Extract year, month, day, hour, minute, and second
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(now.getDate()).padStart(2, '0');
            const hour = String(now.getHours()).padStart(2, '0');
            const minute = String(now.getMinutes()).padStart(2, '0');
            const second = String(now.getSeconds()).padStart(2, '0');

            // Combine into a single string
            const dateTimeString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            const filename = `sepa_${dateTimeString}.xml`;

            downloadXML(response.data, filename)
            dispatch(appLoading({value: false}))
        }
    })

    const proccessSepaFile = () => {
        const files = dropzoneFiles

        if (typeof files[0] === 'undefined') {
            return
        }

        const file = files[0]

        if (!file.valid) {
            Notificator.error('Archivo inválido')
            return
        }

        uploadMutation.mutate({
            file: file.file,
            collectionDate: collectionDate
        })
    }

    useEffect(() => {
        proccessSepaFile()
    }, [dropzoneFiles]);

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <h1 className={"pb-4"}>Informes</h1>
                    </Row>

                    <Row>
                        <Card className={"px-0"}>
                            <Card.Header>
                                <strong>Informe de inscripciones</strong>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Mes
                                            </Form.Label>
                                            <MonthSelector
                                                onChange={(month: number) => setSearchFilters({
                                                    ...searchFilters, month: month
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Año
                                            </Form.Label>

                                            <YearSelector
                                                onChange={(year: number) => setSearchFilters({
                                                    ...searchFilters, year: year
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className={"d-flex flex-column"}>
                                        <div className="mt-auto">
                                            <Button
                                                className={"purple-btn ml-auto"}
                                                onClick={excelExport}
                                                disabled={exporting}
                                            >
                                                {exporting && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                                <>Exportar</>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>

                    <br/><br/>
                    <Row>
                        <Card className={"px-0"}>
                            <Card.Header>
                                <strong>Informe de actividades</strong>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Actividad&nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <ActivitySelect
                                                onChange={(option: any) => setActivitySearchFilters({
                                                    ...activitySearchFilters, activity: option.value
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Mes&nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <MonthSelector
                                                onChange={(month: number) => setActivitySearchFilters({
                                                    ...activitySearchFilters, month: month
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Año&nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <YearSelector
                                                onChange={(year: number) => setActivitySearchFilters({
                                                    ...activitySearchFilters, year: year
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className={"d-flex flex-column"}>
                                        <div className="mt-auto">
                                            <Button
                                                className={"purple-btn ml-auto"}
                                                onClick={excelActivityExport}
                                                disabled={exportingActivity || (!activitySearchFilters.activity || !activitySearchFilters.month || activitySearchFilters.month < 0)}
                                            >
                                                {exportingActivity && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                                <>Exportar</>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>

                    <br/><br/>
                    <Row>
                        <Card className={"px-0"}>
                            <Card.Header>
                                <strong>Informe de recibos</strong>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Mes&nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <MonthSelector
                                                onChange={(month: number) => setPaymentSearchFilters({
                                                    ...paymentSearchFilters, month: month
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId={"queryFilter"}>
                                            <Form.Label>
                                                Año
                                            </Form.Label>

                                            <YearSelector
                                                onChange={(year: number) => setPaymentSearchFilters({
                                                    ...paymentSearchFilters, year: year
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className={"d-flex flex-column"}>
                                        <div className="mt-auto">
                                            <Button
                                                className={"purple-btn ml-auto"}
                                                onClick={excelPaymentExport}
                                                disabled={exportingPayment || paymentSearchFilters.month < 1}
                                            >
                                                {exportingPayment && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                                <>Exportar</>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>

                    <br/><br/>
                    <Row>
                        <h1 className={"pb-4"}>XML de pagos</h1>
                    </Row>

                    <Row>
                        <Card className={"px-0"}>
                            <Card.Header>
                                <strong>Generar archivo XML para pagos SEPA</strong>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group controlId={"reportFileForSepaXml"}>
                                            <Form.Label>
                                                Fecha de cobro &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <input
                                                type={"date"}
                                                className={"form-control"}
                                                onChange={(e: any) => setCollectionDate(e.target.value)}
                                            />
                                            <br/>

                                            <Form.Label>
                                                Adjunta el informe de recibos &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            {
                                                !collectionDate && <p className={"text-danger"}>Selecciona primero una fecha de cobro.</p>
                                            }
                                            {
                                                collectionDate &&
                                                <FileUpload
                                                    defaultFiles={dropzoneFiles}
                                                    onChange={(files) => setDropzoneFiles(files)}
                                                    acceptedMimmeTypes={ALLOWED_SEPA_XML_FILETYPES.join(",")}
                                                    allowedFilesText={"Solo se admite el archivo excel generado a través del \"Informe de recibos\""}
                                                    maxFiles={1}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
